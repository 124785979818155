.category {
    gap: 0 !important;

    >h2 {
        font-size: 24px;
        font-weight: 600;
        line-height: 29.05px;
        text-align: left;
        margin-bottom: 20px;
        color: #2c2c2c;
    }

    @media screen and (max-width: 767px) {
        >h2 {
            font-size: 18px;
            
        }
    }

    &_item {
        padding: 24px;
        border-radius: 12px;
        background: #ffffff;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 768px) {
            padding: 0;
            background:transparent;
        }
    }

    .faq_item {
        margin-bottom: 16px;
        border-bottom: 1px solid #eaeaea;
        overflow: hidden;
        .arrow_icon{
            transform: rotate(-90deg);
        }

        &[open] .arrow_icon {
            transform: rotate(0deg);
        }
        @media screen and (max-width: 768px) {
            @apply bg-white rounded-xl;
            border-bottom: 0;

        }
    }

    .faq_summary {
        padding: 16px 8px;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 20px;
        font-weight: 600;
        line-height: 21.78px;
        cursor: pointer;

        @media screen and (max-width: 768px) {
            font-size: 18px;
            font-weight: 500;
        }
    }

    .arrow_icon {
        transition: transform 0.3s;
    }

    .faq_content {
        padding: 16px;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        color: #2c2c2c;
        animation: fadeIn 0.3s ease-in-out;

        @media screen and (max-width: 768px) {
            font-size: 16px;
            color: #616161;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-5px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}