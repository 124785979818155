.container {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1280px) {
    padding: 0 1rem;
  }

  &_title {
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 29px;
    color: var(--text-black);
    margin-bottom: 20px;

    @media screen and (max-width: 767px) {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &_body {
    display: flex;
    width: 100%;
    gap: 1rem;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      gap: 8px;
    }

    .apartments {
      width: 330px;
      border-radius: 12px;
      background-color: var(--white);
      padding: 24px;
      height: 249px;

      @media screen and (max-width: 767px) {
        width: 100%;
        height: 210px;
      }

      .title {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.5rem;
        color: var(--text-black);
        margin-bottom: 24px;
      }

      .body {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;

        a {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: var(--blue);
            margin: 0;
          }

          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: var(--text-grey);
            margin: 0;
          }
        }
      }
    }

    .complexes {
      width: 330px;
      border-radius: 12px;
      background: var(--white);
      padding: 24px;
      position: relative;
      height: 249px;

      @media screen and (max-width: 767px) {
        width: 100%;
        height: 210px;
      }

      .title {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.5rem;
        color: var(--text-black);
        margin-bottom: 24px;
      }

      .body {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        position: relative;
        z-index: 1;

        a {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: var(--blue);
        }
      }

      .image {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 176px;
        height: 176px;

        @media screen and (max-width: 767px) {
          width: 125px;
          height: 125px;
          bottom: 10px;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .calculator {
      flex: 1;
      display: flex;
      justify-content: space-between;
      height: 249px;
      background: var(--white);
      border-radius: 12px;
      padding: 24px;
      position: relative;

      @media screen and (max-width:768px) {
        height: 193px;
      }

      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        h3 {
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: var(--text-black);
          margin-bottom: 24px;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: var(--text-dark-grey);
          margin: 0;
        }

        a {
          margin-top: auto;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: var(--green);
          background-color: var(--btn-light-green);
          padding: 10px 12px;
          border-radius: 6px;
          white-space: nowrap;

          @media screen and (max-width: 767px) {
            margin-top: 12px;
          }
        }
      }

      .right {
        position: absolute;
        bottom: 10px;
        right: 42px;
        width: 176px;
        height: 176px;

        @media screen and (max-width:768px) {
          width: 125px;
          height: 125px;
          right: 0;
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}