.about_complex_text {
  position: relative;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 10;
  max-height: 240px;
}

.about_complex_text::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
  pointer-events: none;
}

.details {
  @apply overflow-visible;
}

.details[open] .about_complex_text {
  -webkit-line-clamp: unset;
  max-height: unset;
}

.details[open] .about_complex_text::after {
  @apply hidden;
}

.brand_link {
  @apply hidden mt-3;
}

.details[open] .brand_link {
  @apply block;
}

.details[open] .details_all {
  @apply hidden;
}

.arrow_icon {
  transition: transform 0.3s;
}