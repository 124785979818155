.magazines_cont {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-flow: dense;
  gap: 1rem;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .magazine {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border-radius: 12px;
    grid-column: span 3;
    padding: 24px;
    cursor: pointer;
    overflow: hidden;
    position: relative;


    @media screen and (max-width: 767px) {
      padding: 0;
      position: relative;

      .tags {
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        z-index: 1;
        margin: 24px;

        @media screen and (max-width: 767px) {
          margin: 12px;
        }

        span {
          display: flex;
          background: var(--white);
          border-radius: 15px;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: var(--text-black);
          padding: 4px 16px;
          box-shadow: 0px 0px 23px -3px rgba(34, 60, 80, 0.4);

          @media screen and (max-width: 767px) {
            padding: 4px 6px;
          }
        }
      }

      .more_info {
        padding: 12px;

        .date {
          font-size: 12px;
        }
      }
    }



    .image {
      display: none;
      gap: 4px;
      position: relative !important;
      width: 767px;
      height: 375px;
      flex: 1;

      :global {
        .ant-skeleton-element {
          width: 100%;
        }

        .ant-skeleton-image {
          height: 100%;
          width: 100%;
        }
      }

      @media screen and (max-width: 768px) {
        display: flex !important;

      }

      @media screen and (max-width: 991px) {
        width: auto;
      }

      @media screen and (max-width: 767px) {
        height: 320px;
        width: auto;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      span {
        width: 100% !important;
        max-height: 100% !important;
      }


    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      margin-bottom: 1rem;

      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: var(--text-dark-grey);

        @media screen and (max-width: 767px) {
          font-size: 13px;
          color: #2c2c2c;
        }
      }
    }

    .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: var(--text-black);
      margin-bottom: 16px;
    }

    .date {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: var(--text-grey);
    }

    &:nth-child(8n + 1) {
      grid-column: span 5;
    }

    &:nth-child(8n + 5) {
      grid-column: 4/9;
    }

    &:nth-child(8n + 1),
    &:nth-child(8n + 5) {
      grid-row: span 3;
      padding: 0;

      .image {
        display: flex !important;
        gap: 4px;
        position: relative !important;
        width: 767px;
        height: 375px;
        flex: 1;

        @media screen and (max-width: 991px) {
          width: auto;
        }

        @media screen and (max-width: 767px) {
          height: 320px;
          width: auto;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        span {
          width: 100% !important;
          max-height: 100% !important;
        }
      }

      .tags {
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        z-index: 1;
        margin: 24px;

        @media screen and (max-width: 767px) {
          margin: 12px;
        }

        span {
          display: flex;
          background: var(--white);
          border-radius: 15px;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: var(--text-black);
          padding: 4px 16px;
          box-shadow: 0px 0px 23px -3px rgba(34, 60, 80, 0.4);

          @media screen and (max-width: 767px) {
            padding: 4px 6px;
          }
        }
      }

      .more_info {
        padding: 24px;

        @media screen and (max-width: 767px) {
          padding: 12px;
        }

        .title {
          font-weight: 600;
          font-size: 32px;
          line-height: 39px;
          color: var(--text-black);
          margin-bottom: 12px;

          @media screen and (max-width: 767px) {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
          }
        }

        .date {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: var(--text-grey);

          @media screen and (max-width: 767px) {
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
          }
        }
      }
    }
  }
}


@media screen and (max-width: 767px) {
  .all {
    display: flex;
    flex-direction: column;

    .image {
      height: auto !important;

      img {
        position: inherit !important;
      }
    }
  }
}