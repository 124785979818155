.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;

  @media screen and (max-width: 1280px) {
    padding: 0 1rem;
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: var(--text-black);
      margin: 0;

      @media screen and (max-width: 767px) {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }
    }

    span {
      display: flex;
      align-items: center;
      margin: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: var(--green);

      svg {
        margin-left: 13px;

        path {
          stroke: var(--green);
        }
      }
    }
  }
}