.magazines {
    position: relative;
}

.container {
    width: 100%;
    margin: 0 auto;

    :global {

        .control-arrow,
        .control-dots {
            @apply hidden #{!important};
        }

        .slider-swiper .slider li {
            @apply h-max #{!important};
        }

        .slider-swiper .slider {
            @apply gap-4 w-[400px] #{!important};
        }
    }
}

.magazine {
    height: 350px;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border-radius: 12px;
    justify-content: space-between;
    cursor: pointer;
}


.tags {
    @apply text-center rounded-[15px] py-1 px-[6px] bg-white absolute top-6 left-6 z-[1] flex justify-center items-center;

    span {
        @apply text-[13px] leading-[15.73px] text-black font-black;
    }

}

.image {
    width: 100%;
    flex: 1;
    position: relative;
    object-fit: contain;
    border-radius: 12px;
    height: 150px;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }
}

.more_info {
    @apply flex flex-col gap-3 p-6;

    .title {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        width: 100%;
        color: rgba(44, 44, 44, 1);
        @apply text-[20px] font-semibold;
    }

    span {
        @apply text-base font-medium text-gray-400;
    }
}



.date {
    font-size: 14px;
    color: #777;
}


.custom_pagination_container {
    @apply flex justify-center items-center mt-2 w-full;

    .custom_pagination {
        @apply w-max h-[15px] flex justify-center gap-1 items-center rounded-[37px] bg-white py-[6px] px-3;

        :global {
            .swiper-pagination-bullet {
                background-color: rgba(0, 0, 0, 0.4);
                @apply w-3 h-[3px] rounded m-0;
            }

            .swiper-pagination-bullet-active {
                background-color: #12B76A;
                @apply w-5 h-[3px] rounded;
            }
        }
    }
}