.new_buildings {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  position: relative;

  @media screen and (max-width: 1240px) {
    padding: 0 16px;
    margin-bottom: 24px;
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: var(--text-black);
    margin-bottom: 20px;

    @media screen and (max-width: 767px) {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 24px;
    }


  }

  .body {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    row-gap: 30px;
    position: relative;


    @media screen and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 1rem;
    }
  }

  .bottom {
    display: flex;
    justify-content: center;
    margin-top: 24px;

    @media screen and (max-width: 767px) {
      margin-top: 1rem;
    }

    a {
      display: flex;
      align-items: center;
      height: 43px;
      background: var(--white);
      border: 1px solid var(--border-grey);
      border-radius: 6px;
      padding: 0 2rem;

      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: var(--green);

    }
  }
}

.reklama {
  &_right {
    position: sticky;
    width: 296px;
    margin-top: 46px;
    display: block;
    height: max-content;
    top: 100px;


    @media screen and (max-width: 1600px) {
      display: none;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }

    .image_container {
      position: relative;
      width: 296px;
      height: 600px;
      border-radius: 12px;
      overflow: hidden;
    }

  }

  &_tag {
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    margin: 0.75rem;
    gap: 0.25rem;
    z-index: 1;

    >span {
      display: flex;
      background: var(--white);
      border-radius: 0.9375rem;
      font-weight: 400;
      font-size: 0.8125rem;
      line-height: 1rem;
      color: var(--text-black);
      padding: 0.25rem 1rem;
      box-shadow: 0px 0px 23px -3px rgba(34, 60, 80, 0.4);
    }
  }
}


.container {
  width: 100%;
  margin: 0 auto;

  :global {

    .control-arrow,
    .control-dots {
      @apply hidden #{!important};
    }

    .slider-swiper .slider li {
      @apply h-max #{!important};
    }

    .slider-swiper .slider {
      @apply gap-4 w-[400px] #{!important};
    }
  }
}

.custom_pagination_container {
  @apply flex justify-center items-center mt-2 w-full;

  .custom_new_complexes_pagination,
  .custom_latest_complexes_pagination,
  .custom_ready_complexes_pagination {
    @apply w-max h-[15px] flex justify-center gap-[4px] items-center rounded-[37px] bg-white py-[6px] px-3;

    :global {
      .swiper-pagination-bullet {
        background-color: rgba(0, 0, 0, 0.4);
        @apply w-3 h-[3px] rounded m-0;
      }

      .swiper-pagination-bullet-active {
        background-color: #12B76A;
        @apply w-5 h-[3px] rounded;
      }
    }
  }
}

.all_link {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 20px;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: var(--green) !important;

  svg {
    margin-left: 13px !important;

    path {
      stroke: var(--green) !important;
    }
  }
}