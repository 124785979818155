.bannerMain {
  @media screen and (max-width: 640px) {
    @apply w-[90%] my-0 mx-auto;
  }
}

.bannerLogo {
  @media screen and (max-width: 640px) {
    @apply hidden;
  }
}