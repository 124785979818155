.wrap {
  background: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(10px);

  :global {
    .ant-modal-body {
      padding: 0;
      position: relative;
    }

    .ant-modal-content {
      background: transparent;
      box-shadow: none;
    }

    .ant-modal-close {
      display: none;
    }
  }
}

.drawer {
  :global {
    .ant-drawer-header {
      display: none;
    }

    .ant-drawer-content-wrapper {
      height: 100vh !important;
    }

    .ant-drawer-body {
      padding: 0;
    }
  }
}