.main_top {
  width: 100% !important;
  background-image: url(../../../../assets/images/top-bg.webp);
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  height: 411px;
  margin-bottom: 16px;
  position: relative;

  @media screen and (max-width: 767px) {
    height: unset;
    background-image: none;
  }

  &_top {
    margin: auto;
    z-index: 1;
    position: relative;
    height: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 280px;
      width: 100%;
      background-image: url(../../../../assets/images/top-bg.webp);
      padding: 24px 1rem;
      background-size: cover;
      background-position: right;
    }

    h1 {
      font-weight: 700;
      font-size: 48px;
      line-height: 58px;
      color: var(--white);
      text-align: center;
      margin-bottom: 64px;
      z-index: 1;
      margin-top: 0;

      @media screen and (max-width: 767px) {
        margin-top: 0;
        margin-bottom: 100px;
        font-weight: 700;
        font-size: 26px;
        line-height: 31px;
      }
    }

  }

}

.reklama {
  position: absolute;
  left: calc((100vw - 100%) / -2);
  top: 0;
  width: 100vw;
  height: 100%;

  &_back {
    background: rgba(24, 41, 48, .4);
    z-index: 1;
  }

  &_bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    &_main {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(24, 41, 48, .4);
      z-index: 2;
    }

    img {
      object-fit: cover;
    }
  }

  >img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

}