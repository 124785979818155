.builder_card {
  background: var(--white);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  padding: 24px 48px 24px 24px;

  @media screen and (max-width: 767px) {
    padding: 24px;

    &.list {
      flex-direction: column-reverse;
      align-items: flex-start;
      row-gap: 12px;
    }
  }

  &_left {
    display: flex;
    flex-direction: column;
    margin: 0 !important;

    h3 {
      font-weight: 600;
      font-size: 20px;
      color: var(--green);
      margin-bottom: 14px;
      cursor: pointer;

      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: var(--text-dark-grey);
      margin-bottom: 14px;
    }

    .phones_cont {
      display: flex;
      flex-direction: column;
      row-gap: 14px;

      a {
        font-weight: 700;
        font-size: 16px;
        width: max-content;
        line-height: 19px;
        color: var(--text-black);
      }
    }
  }

  &_right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 134px;
    height: 84px;
    position: relative;

    img {
      width: 134px;
    }

    @media screen and (max-width: 767px) {
      min-width: 92px;
      height: 58px;

      img {
        width: 70px;
        height: 50px;
      }
    }
  }
}